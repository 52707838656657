.styled-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  background: #fff;
  border-radius: 15px;
}

.styled-scrollbar::-webkit-scrollbar-track {
  background: rgba(#8a8e8f, 0.7);
  border-radius: 15px;
  opacity: 0.42;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #0c355e;
}

.styled-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(#0c355e, 0.8);
}

.no-decoration {
  text-decoration: none;
}

.underline-on-hover:hover {
  text-decoration: underline;
}
.color-blue-on-hover:hover {
  color: #0074ff;
}
.scale-on-hover {
  transition: transform 200ms;
}

.scale-on-hover:hover {
  transform: scale(1.05);
}

.input-number-without-arrows::-webkit-outer-spin-button,
.input-number-without-arrows::-webkit-inner-spin-button {
  /* Chrome, Safari, Edge, Opera */
  -webkit-appearance: none;
  margin: 0;
  /* display: none; <- Crashes Chrome on hover */
}

.input-number-without-arrows[type='number'] {
  /* Firefox */
  appearance: textfield;
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

.border-top-none {
  border-top: none !important;
}

:root {
  --toastify-color-success: #0ab87b;
  --toastify-icon-color-success: #0ab87b;
  --toastify-color-error: #ff4d4d;
  --toastify-icon-color-error: #ff4d4d;
}

.MuiPickersDay-root[disabled] {
  opacity: 0.5;
}

.text-no-wrap {
  text-wrap: nowrap;
}

.main-border-on-hover {
  border: 1px solid transparent;
  transition: border-color 200ms !important;
}

.main-border-on-hover:hover {
  border-color: #13628c;
}

.phone-input {
  opacity: 0.5;
}

.phone-input:focus {
  opacity: 1;
}

.swiper-scrollbar-drag {
  background: #333 !important;
  opacity: 0.3;
  height: 4px;
}
.swiper-scrollbar {
  background: #e8e8ea !important;
  opacity: 0.5;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
